export const appIsArray = (value: unknown): value is [] => Array.isArray(value);
export const randomFromArray = (array: unknown[]): unknown =>
  array[Math.floor(Math.random() * array.length)];

export const isEqualArrays = (
  arrayOne: Array<string | number>,
  arrayTwo: Array<string | number>,
): boolean => {
  if (arrayOne.length !== arrayTwo.length) return false;
  for (const element of arrayOne) {
    if (!arrayTwo.includes(element)) return false;
  }

  return true;
};
