import { t } from '@lingui/macro';
import moment from 'moment';

export const dateUtils = moment;

type MonthType = 'short' | 'long';

export function getMonthName(date: Date, month: MonthType = 'long') {
  const longMonths = [
    t`January`,
    t`February`,
    t`March`,
    t`April`,
    t`May`,
    t`June`,
    t`July`,
    t`August`,
    t`September`,
    t`October`,
    t`November`,
    t`December`,
  ];
  const shortMonth = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return month === 'short' ? shortMonth[date.getMonth()] : longMonths[date.getMonth()];
}

export const getWeekDay = (d: Date, day: MonthType = 'long'): string => {
  const weekdayLong = [
    t`Sunday`,
    t`Monday`,
    t`Tuesday`,
    t`Wednesday`,
    t`Thursday`,
    t`Friday`,
    t`Saturday`,
  ];

  const weekdayShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return day === 'long' ? `${weekdayLong[d.getDay()]}` : `${weekdayShort[d.getDay()]}`;
};

export const formatDate = (date: string | Date, format = 'll', utc = false) => {
  if (utc) {
    return dateUtils.utc(date).format(format);
  }

  return dateUtils(date).format(format);
};

export const durationDays = (startDate: string | Date, endDate: string | Date, utc = false) => {
  const getDayText = (duration: number): string => {
    return duration === 1 ? 'day' : 'days';
  };

  if (utc) {
    const from = dateUtils.utc(startDate);
    const to = dateUtils.utc(endDate);
    const duration = to.diff(from, 'days');
    return `${duration} ${getDayText(duration)}`;
  }

  const from = dateUtils(startDate);
  const to = dateUtils(endDate);
  let duration = to.diff(from, 'days');
  if (duration === 0) duration = 1;
  return `${duration} ${getDayText(duration)}`;
};

export const monthNamesList = (): string[] => {
  return dateUtils.months();
};

export const yearsBack = (fromYear: number): string[] => {
  const projectStartYear = fromYear;
  const year = new Date().getFullYear();
  const years = year - projectStartYear;
  return [...Array(years)].map((_, i) => `${year - i}`);
};

export const apiDateFormat = (date: Date): string => {
  return dateUtils(date).format('YYYY-MM-DD');
};

export const isValidDate = (date: string): boolean => {
  const d = new Date(date);

  return !isNaN(d.valueOf()) && d.getFullYear() > 2000;
};

const addZero = (num: number) => {
  return num < 10 ? '0' + num : num;
};

export const getAMPMHoursFormat = (date: Date): string => {
  // e.g. 3PM
  const hours = date.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const resultHours = addZero(hours > 12 ? hours - 12 : hours);

  return `${resultHours}${ampm}`;
};

export const getAMPMTimeFormat = (date: Date): string => {
  // e.g. 3:46 PM
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const resultHours = addZero(hours > 12 ? hours - 12 : hours);
  const resultMinutes = addZero(minutes);

  return `${resultHours}:${resultMinutes} ${ampm}`;
};

export const getAMPMTimeFormatWithDate = (date: Date) => {
  return formatDate(date, t`MMM D, YYYY [at] h:mm A`);
};

export const DMTimeFormat = (d: Date): string => {
  // e.g. 18, Nov
  return `${addZero(d.getDate())} ${getMonthName(d, 'short')}`;
};

export const DDMMYYYYFormat = (d: Date) => {
  return `${addZero(d.getDate())}.${addZero(d.getMonth() + 1)}.${d.getFullYear()}`;
};

export const MDYFormat = (d: Date): string => {
  // e.g. July 12, 2022
  const monthName = getMonthName(d);

  return `${monthName}, ${d.getDate()}, ${d.getFullYear()}`;
};

export const MYFormat = (d: Date): string => {
  // e.g. Jan, 2022
  const monthName = getMonthName(d, 'short');

  return `${monthName}, ${d.getFullYear()}`;
};

export const MFormat = (d: Date): string => {
  // e.g. Jan, 2022
  const monthName = getMonthName(d, 'short');

  return `${monthName}`;
};
