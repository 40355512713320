import * as Lib from 'google-libphonenumber';

export interface PhoneNumberInfo {
  countryCode: string;
  phoneCode: string;
  phoneNumber: string;
}
export class PhoneConverter {
  private phoneNumberUtil: Lib.PhoneNumberUtil;

  constructor() {
    this.phoneNumberUtil = new Lib.PhoneNumberUtil();
  }

  parsePhoneNumber(phoneNumber: string): PhoneNumberInfo {
    try {
      const phoneNumerInstance = this.phoneNumberUtil.parse(phoneNumber);
      const countryCode = this.phoneNumberUtil.getRegionCodeForNumber(phoneNumerInstance) || '';
      return {
        countryCode: countryCode.toLowerCase(),
        phoneCode: `${phoneNumerInstance.getCountryCode()}`,
        phoneNumber: `${phoneNumerInstance.getNationalNumber()}`,
      };
    } catch (ex) {
      return {
        countryCode: '',
        phoneCode: '',
        phoneNumber: '',
      };
    }
  }

  isValidNumber(phoneNumber: string): boolean {
    try {
      const phoneNumerInstance = this.phoneNumberUtil.parse(phoneNumber);
      return this.phoneNumberUtil.isValidNumber(phoneNumerInstance);
    } catch (ex) {
      return false;
    }
  }
}
