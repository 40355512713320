export const parseArrayBuffer = (data: ArrayBuffer) => {
  if (!data) return null;
  let result = null;
  try {
    const decoder = new TextDecoder();
    const str = decoder.decode(data);
    result = JSON.parse(str);
  } catch (ex) {}
  return result;
};
