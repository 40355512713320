import { roundNumber } from '@shared/utils/number';
import { BigNumber } from 'bignumber.js';

export const money = (value: number): number => roundNumber(value, 2);

export const priceForAmount = (rate: number, amount: number): number => {
  const amountInteger = sanitizedAmount(amount);

  return money(amountInteger * rate);
};

export const sanitizedAmount = (amount: number | string, toInt = false): number => {
  let _amount = 0;
  if (typeof amount === 'string') {
    const cleanedAmount = amount.replace(/[e\+\-]/gi, '').replace(/[^\d.]/g, '');
    _amount = parseFloat(cleanedAmount);
    if (!_amount) {
      _amount = 0;
    }
  } else {
    _amount = amount;
  }
  return toInt ? Math.abs(Math.floor(_amount)) : Math.abs(_amount);
};

export const formatAmount = (amount: number | string, digitsCountAfterDot: number): string => {
  let _amount;
  if (typeof amount === 'string') {
    let cleanedAmount = amount.replace(/[e\+\-]/gi, '').replace(/[^\d.]/g, '');
    _amount = cleanedAmount;
    if (!_amount) {
      _amount = '0';
    }
  } else {
    _amount = `${amount}`;
  }

  const [integerPart, fractionalPart] = _amount.split('.');

  if (/[.]/g.test(_amount)) {
    return `${Intl.NumberFormat('en-EN').format(Number(integerPart))}.${fractionalPart?.substring(
      0,
      fractionalPart.length > digitsCountAfterDot ? digitsCountAfterDot : fractionalPart.length,
    )}`;
  } else {
    return Intl.NumberFormat('en-EN').format(Number(integerPart));
  }
};

export const amountToNumber = (amount: string): number => {
  const cleanedAmount = amount.replace(/[, ]/g, '');
  return parseFloat(cleanedAmount);
};

export const amountToBigNumber = (amount: string): BigNumber => {
  return new BigNumber(amountToNumber(amount));
};
