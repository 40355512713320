export const transformToBaseCurrency = (currency: string) => {
  switch (true) {
    case /USD/g.test(currency.toUpperCase()):
      return 'USD';
    case /AUD/g.test(currency.toUpperCase()):
      return 'AUD';
    case /EUR/g.test(currency.toUpperCase()):
      return 'EUR';
    case /GBP/g.test(currency.toUpperCase()):
      return 'GBP';
    case /GSC/g.test(currency.toUpperCase()):
    case /XAU/g.test(currency.toUpperCase()):
      return 'Gold';
    default:
      return currency;
  }
};
