import { measureHeight } from 'react-div-100vh';

import { browser } from './browser';

export enum Layout {
  mobile = 'xs', //0px - 500px
  tablet = 'md', //500px - 1280px
  laptop = 'md', //500px - 1280px
  desktop = 'lg', //1280px - 1920px
  desktopWide = 'xl', //1920px ->
}

export enum CSS_VARIABLES {
  //New styles
  colorPrimary100 = '--app-color-primary-100',
  colorPrimary200 = '--app-color-primary-200',
  colorPrimary300 = '--app-color-primary-300',
  colorPrimary400 = '--app-color-primary-400',
  colorPrimary500 = '--app-color-primary-500',
  colorPrimary600 = '--app-color-primary-600',

  colorSecondary100 = '--app-color-secondary-100',
  colorSecondary200 = '--app-color-secondary-200',
  colorSecondary300 = '--app-color-secondary-300',
  colorSecondary400 = '--app-color-secondary-400',
  colorSecondary500 = '--app-color-secondary-500',
  colorSecondary600 = '--app-color-secondary-600',

  colorWarning100 = '--app-color-warning-100',
  colorWarning200 = '--app-color-warning-200',
  colorWarning300 = '--app-color-warning-300',
  colorWarning400 = '--app-color-warning-400',
  colorWarning500 = '--app-color-warning-500',
  colorWarning600 = '--app-color-warning-600',

  colorError100 = '--app-color-error-100',
  colorError200 = '--app-color-error-200',
  colorError300 = '--app-color-error-300',
  colorError400 = '--app-color-error-400',
  colorError500 = '--app-color-error-500',
  colorError600 = '--app-color-error-600',

  colorSuccess100 = '--app-color-success-100',
  colorSuccess200 = '--app-color-success-200',
  colorSuccess300 = '--app-color-success-300',
  colorSuccess400 = '--app-color-success-400',
  colorSuccess500 = '--app-color-success-500',
  colorSuccess600 = '--app-color-success-600',

  colorGrey50 = '--app-color-grey-50',
  colorGrey100 = '--app-color-grey-100',
  colorGrey200 = '--app-color-grey-200',
  colorGrey300 = '--app-color-grey-300',
  colorGrey400 = '--app-color-grey-400',
  colorGrey500 = '--app-color-grey-500',
  colorGrey600 = '--app-color-grey-600',
  colorGrey700 = '--app-color-grey-700',
  colorGrey800 = '--app-color-grey-800',
  colorGrey900 = '--app-color-grey-900',

  colorAdditionalWhite = '--app-color-additional-white',
  colorAdditionalGrape = '--app-color-additional-grape',
  colorAdditionalBlueLight = '--app-color-additional-blue-light',
  colorAdditionalError = '--app-color-additional-error',

  //Old styles
  fontFamily = '--app-font-family-main', //computed

  appHeight = '--app-height', //computed
  accentColor = '--app-accent-color', //#4162EA Accent color. For clickable items.
  accentColorHover = '--app-accent-color-hover', //#4162EA Accent color. For clickable items.
  disabledColor = '--app-disabled-color', //#BCC0C7 For icons or disabled items
  errorColor = '--app-error-color', //#FF2C2C For error states
  mainColor = '--app-main-color', //#323232 For documents-buttons and other controls

  textTitleColor = '--app-text-title-color', //#000000 Mostly for titles
  textSecondaryColor = '--app-text-secondary-color', //#505153 For secondary texts, explanatory
  textSubTitlesColor = '--app-text-sub-titles-color', //#79818F For subtitles and captions
  textInputsColor = '--app-text-input-color', //#BCC0C7 For text input

  backgroundMainColor = '--app-bg-main-color', //#FAF9FC Mobile navbar, some bgr for web
  backgroundOverlayColor = '--app-bg-overlay-color', //#FAF9FC Mobile navbar, some bgr for web
  backgroundControlsColor = '--app-bg-controls-color', //#EDF1F3 For some icons and controls
  backgroundEmptyColor = '--app-bg-empty-color', //#FFFFFF For dashboard backgrouds

  bordersMainColor = '--app-borders-main-color', //#EEEEEE

  dashboardWidth = '--app-dashboard-width', //#1152px
  headerHeight = '--app-header-height', //#50px
  menuWidth = '--app-menu-width', //#153px
  menuWidthMinimized = '--app-menu-width-minimized', //#92px

  mainError = '--app-main-error', //#FF2C2C
  mainSuccess = '--app-main-success', //#1FAE70

  controlDarkColor = '--app-dark-control-color', //##323232
  controlDarkColorHover = '--app-dark-control-color-hover', //##000000
  controlDarkColorHoverBackground = '--app-dark-control-color-hover-background', //#EEEEEE
  controlDarkColorDisabled = '--app-dark-control-color-disabled', //###BCC0C7

  controlLightColor = '--app-light-control-color', //##4162EA
  controlLightColorHover = '--app-light-control-color-hover', //##738EFE
}

export const _var = (variableKey: CSS_VARIABLES): string => {
  return `var(${variableKey})`;
};

export const getScreenHeight = () => {
  return measureHeight() as number;
};

export const getScreenHeightSource = () => {
  if (browser?.name === 'ie') {
    return '100vh';
  }
  return _var(CSS_VARIABLES.appHeight);
};
