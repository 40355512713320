import { ProductModel, ProductType } from '@shared/models/products/product-model';

export const getFirstSmartCoinCurrencyName = (products: Array<ProductModel>) => {
  return products.find((product) => product.asJson.type === ProductType.SMART_COIN)?.asJson.name;
};

export const getFirstMetalName = (products: Array<ProductModel>) => {
  return products.find((product) => product.asJson.type === ProductType.SMART_COIN)?.asJson
    .baseCurrency;
};
