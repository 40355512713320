export enum SmartCoinOrderStatusInternal {
  NEW = 'New',
  PENDING = 'Pending',
  DEPOSIT_IN_PROGRESS = 'DepositInProgress',
  DEPOSIT_REQUIRES_ACTION = 'DepositRequiresAction',
  PAYMENT_IN_PROGRESS = 'PaymentInProgress',
  PAYMENT_RESERVED = 'PaymentReserved',
  METAL_TRADED = 'MetalTraded',
  OVERDRAFT_TAKEN = 'OverdraftTaken',
  OVERDRAFT_IN_PROGRESS = 'OverdraftInProgress',
  METAL_EXCHANGED = 'MetalExchanged',
  CONFIRMATION_IN_PROGRESS = 'ConfirmationInProgress',
  METAL_MINTED = 'MetalMinted',
  SMART_COIN_BLOCKCHAIN_TRANSFERRED = 'SmartCoinBlockchainTransferred',
  METAL_TRADE_QUANTITY_CALCULATED = 'MetalTradeQuantityCalculated',
  SMART_COIN_TRANSFER_RESERVED = 'SmartCoinTransferReserved',
  PAID = 'Paid',
  OVERDRAFT_PAID_BACK = 'OverdraftPaidBack',
  SMART_COIN_DEPOSITED = 'SmartCoinDeposited',
  EXECUTED = 'Executed',
  CANCELED = 'Canceled',
  FAILED = 'Failed',
  SMART_COIN_TRANSFERRED = 'SmartCoinTransferred',
  SMART_COIN_TRANSFER_IN_PROGRESS = 'SmartCoinTransferInProgress',
  METAL_EXCHANGE_IN_PROGRESS = 'MetalExchangeInProgress',
  SMART_COIN_BLOCKCHAIN_TRANSFER_IN_PROGRESS = 'SmartCoinBlockchainTransferInProgress',
  SMART_COIN_TRANSFER_CONFIRMATION_IN_PROGRESS = 'SmartCoinTransferConfirmationInProgress',
}

export enum SmartCoinOrderStatusExternal {
  NEW = 'New',
  IN_PROGRESS = 'InProgress',
  SECURE_3D = '3DS',
  EXECUTED = 'Executed',
  CANCELED = 'Canceled',
}